import React from "react"
import { useStaticQuery, graphql } from "gatsby"
const Logo = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        logo {
          mainImage {
            alt
            asset {
              url
            }
          }
        }
      }
    }
  `)

  return (
    <img
      src={data.sanitySiteSettings.logo.mainImage.asset.url}
      alt={data.sanitySiteSettings.logo.mainImage.alt}
      className={className}
    />
  )
}

export default Logo
