import { Link } from "gatsby"
import React, { Component } from "react"
import { Navbar, NavbarBrand, NavbarBurger } from "bloomer"
import "../../styles/header.scss"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"

import {
  faCheckSquare,
  faCoffee,
  faPhoneSquareAlt,
} from "@fortawesome/free-solid-svg-icons"
import { faPhone } from "../../fontAwesome/pro-duotone-svg-icons"

library.add(fab, faCheckSquare, faCoffee, faPhoneSquareAlt, faPhone)

class Header extends Component {
  constructor(props) {
    super(props)
    this.state.isExpanded = false
    this.state = { isOpen: false }
    this.onClickHandler = this.onClickHandler.bind(this)
  }

  state = { active: false, ariaExpanded: "false" }

  onClickHandler() {
    this.setState(isExpanded => ({
      isExpanded: !isExpanded.isExpanded,
    }))

    let toggle = document.querySelector(".nav-toggle")
    let menu = document.querySelector(".navbar-menu")
    toggle.classList.toggle("is-active")
    menu.classList.toggle("is-active")
  }

  render() {
    return (
      <header className="header">
        <Navbar
          className="navbar is-light headerStyle nav-menu"
          role="navigation"
          aria-label="main navigation"
        >
          <NavbarBrand>
            <Link
              activeClassName="activePage"
              className="navbar-item footer-link"
              to="/"
            >
              <span className="navbarGudc">GUDC EU</span>
            </Link>

            {/*  TODO: aria-expanded false først her er en hack, få til å funke skikkelig med staten */}

            <NavbarBurger
              role="button"
              aria-label="Open navigation menu"
              onClick={this.onClickHandler}
              aria-expanded={this.state.isExpanded}
              id="expandable"
              className="nav-toggle"
            />
          </NavbarBrand>

          <div id="navMenu" class="navbar-menu">
            <div class="navbar-start navbar-container">
              <Link
                activeClassName="activePage"
                className="navbar-item footer-link"
                to="/inclusiveCreation"
              >
                Inclusive Creation
              </Link>
              <Link
                activeClassName="activePage"
                className="navbar-item footer-link"
                to="/workshops"
              >
                Workshops
              </Link>
              <Link
                activeClassName="activePage"
                className="navbar-item footer-link"
                to="/aboutUs"
              >
                About us
              </Link>
              <Link
                activeClassName="activePage"
                className="navbar-item footer-link"
                to="/accessibility"
                partiallyActive={true}
              >
                Accessibility
              </Link>

              <Link
                activeClassName="activePage"
                className="navbar-item footer-link"
                to="/contactUs"
              >
                Contact us
              </Link>
            </div>
          </div>
        </Navbar>
      </header>
    )
  }
}

export default Header
