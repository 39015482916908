import React from "react"
import Helmet from "react-helmet"

export default ({
  pageUrl = null,
  type = null,
  title = null,
  description = null,
  image = null,
  appID = null,
}) => (
  <Helmet>
    {<meta property="og:type" content={type} />}
    {<meta property="og:title" content={title} />}
    {<meta property="og:description" content={description} />}
    {
      <meta
        property="og:image"
        content="https://cdn.sanity.io/images/5ce3b3ec/production/cad4bcafff182d7a2151469d7110aa07bb999aae-1025x514.png?w=1000&h=1000&fit=max"
      />
    }
    {<meta property="fb:app_id" content={appID} />}
  </Helmet>
)
