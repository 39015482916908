import { Link, useStaticQuery } from "gatsby"
import React from "react"
import { Container, Footer, Columns, Column, Nav } from "bloomer"
import Logo from "../Parts/logo"
import "../../styles/footer.scss"
import {
  faPhone,
  faAt,
  faChevronCircleRight,
} from "../../fontAwesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
const FooterComponent = () => {
  const data = useStaticQuery(graphql`
    {
      sanityCompanyInfo {
        address1
        city
        country
        email
        name
        phone
      }
    }
  `)

  const footer = data.sanityCompanyInfo
  const mailAddress = "mailto:" + footer.email

  return (
    <Footer>
      <Container>
        <Columns>
          <Column>
            <Nav>
              <ul>
                <li className="bd-footer-link-title">
                  <Link className="footer-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="bd-footer-link-title">
                  <Link className="footer-link" to="/inclusiveCreation">
                    Inclusive Creation
                  </Link>
                </li>
                <li className="bd-footer-link-title">
                  <Link className="footer-link" to="/workshops/">
                    Workshops
                  </Link>
                </li>
                <li className="bd-footer-link-title touchup">
                  <Link className="footer-link" to="/aboutUs/">
                    About us
                  </Link>
                </li>
                <li className="bd-footer-link-title">
                  <Link className="footer-link" to="/accessibility/">
                    Accessibility
                  </Link>
                </li>
                <li className="bd-footer-link-title">
                  <Link className="footer-link" to="/contactUs/">
                    Contact us
                  </Link>
                </li>
              </ul>
            </Nav>
          </Column>

          <Column>
            <Logo className="logoFix" />
          </Column>

          <Column>
            <h2 className="title introTitle">Contact</h2>
            <div className="editorBlock">
              <p className="phone">
                <FontAwesomeIcon
                  className="faIconColors"
                  icon={faPhone}
                  alt="Phone icon in theme colors"
                />

                <span>Phone: </span>
                <a href={footer.phone} aria-label="Contact phone number">
                  {footer.phone}
                </a>
              </p>
              <p className="mail">
                <FontAwesomeIcon
                  icon={faAt}
                  className="faIconColors"
                  alt="An @ icon in theme colors, blue and green"
                />
                <span className="hideText">E-mail: </span>
                <a href={mailAddress}>{footer.email}</a>
              </p>
              <p>{footer.name}</p>
              <p>{footer.address}</p>
              <p>
                <Link to="/contactUs/">
                  <FontAwesomeIcon
                    icon={faChevronCircleRight}
                    className="faIconColors"
                    alt="Icon pointer right"
                  />
                  Contact us
                </Link>
              </p>
            </div>
          </Column>
        </Columns>
        <Columns>
          <Column />
          <Column className="is-centered">
            <div>
              <b>{footer.name}</b>
            </div>
            <span className="has-text-centered">
              <Link
                className="footer-links"
                to="accessibility/accessibilityStatement"
              >
                Accessibility statement
              </Link>
              |
              <Link className="footer-links" to="/gdpr">
                GDPR statement
              </Link>
            </span>
          </Column>
          <Column />
        </Columns>

        <Columns>
          <Column />
          <Column>
            <b>Developed by </b>{" "}
            <a className="footer-links" href="https://fossekalltek.no">
              Fossekall Teknologi AS
            </a>
          </Column>
          <Column />
        </Columns>
      </Container>
    </Footer>
  )
}

export default FooterComponent
